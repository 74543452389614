import './App.css';
import SiftPage from './SiftPage';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import SiftHome from './SiftHome';
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  SignIn,
  SignUp,
  RedirectToSignIn,
} from "@clerk/clerk-react";
import { AppBar, Box, LinearProgress, ThemeProvider, Typography, createTheme } from '@mui/material';
import ConnectAccountPage from './ConnectAccountPage.js';
import { siftsyTheme } from './siftsytheme';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import UserPrefs from './UserPrefs';

const ROOT = process.env.REACT_APP_ROOT_URL;
// const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;


if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const theme = createTheme(siftsyTheme);


function App() {

  const { email } = useParams();
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

  window.addEventListener('resize', () => {
      setPageWidth(window.innerWidth);
  });


  return (
   
<Router>
      <ClerkProvider publishableKey={clerkPubKey} appearance={{
        variables: {
          colorPrimary:"#4727C3",
          fontFamily: "Tahoma, sans-serif",
          fontFamilyButtons: "Tahoma, sans-serif",
          
        }
      }}>
      <Routes>
        <Route
          path="/sign-in/*"
          element={
          <SignIn 
            routing="path" 
            path="/sign-in"
            // initialValues={{emailAddress: "test@test.com"}}
            
            />}
        />
        <Route
          path="/sign-up/*"
          element={
            <div style={{
              width: pageWidth > 550 ? 550 : pageWidth, height: "auto",
              margin: "auto",
              overflowX: "hidden",
              overflowY: "scroll" 
          }}>

<Box sx={{display:"flex", flexDirection:"column", textAlign:"left"}}>

{/* <Helmet>
        <meta charSet="utf-8" />
        <title>siftsy | sign up - join top creators getting valuable audience insights</title>
        <meta name="description" content="siftsy is your ai comment section companion that simplifies and delivers curated insights to your inbox." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="twitter:image" content={`${ROOT}/siftsy_thumbnail.png`} />
        <meta property="og:title" content="siftsy | sign up - join top creators getting valuable audience insights" />
        <meta property="og:image"  content={`${ROOT}/siftsy_thumbnail.png`} />

</Helmet> */}


<ThemeProvider theme={theme}>


<AppBar position="static" elevation={0} sx={{ background: "transparent" }}>

    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-stretch", alignItems: "center", ml:2, mt: 2 }}>


        <img src={"/siftsy_logo.png"} alt="logo" style={{ width: 80, height: "auto", margin: "5px" }} />
        <Typography color="primary" align="center" variant={"subtitle2"}
    sx={{borderRadius:30, background : "rgba(238,234,255, .8)", color: "#4828C5", padding:"1px 12px 1px 12px", ml:-1,
      fontWeight: 600 }} >
      beta </Typography>


        <Box sx={{ flexGrow: 1 }} />
        

    </Box>



</AppBar>
          <Box sx={{m:3}}>
           <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.1em"}}>
            <Typography variant="body1" align="center" color="primary" fontSize={"1.8rem"} fontWeight={700}>Your comments, simplified.</Typography>

            <Typography variant="body1" align="center" color="primary" fontSize={"1.1rem"}><b>Join the <i>top</i> creators getting valuable audience insights in regular updates.</b></Typography>


            <Typography variant="body1" align="center" color="#666"><i>{`Estimated Sign Up Time: <1 min`}</i></Typography>

          </Box>

            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1em", mt:2, mb:4}}>
          <SignUp 
            routing="path" 
            path="/sign-up"
            initialValues={{emailAddress: email}}
            />
            </Box>
</Box>
       </ThemeProvider>
     
            </Box>


            </div>}
        />
        <Route
          path="/:creatorName/:videoId" 
          element={
            <>
            <SignedIn>
            <SiftPage />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn />
           </SignedOut>
          </>
          }
        />
         <Route path="/connect" element={ <>
            <SignedIn>
            <ConnectAccountPage />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn />
           </SignedOut>
          </>} />
          <Route path="/prefs" element={ <>
            <SignedIn>
            <UserPrefs />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn />
           </SignedOut>
          </>} />
        <Route path="/" element={ <>
            <SignedIn>
            <SiftHome />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn />
           </SignedOut>
          </>} />
      </Routes>
      </ClerkProvider>
      </Router>

  
  );
}

export default App;
