import React, { useState } from "react";
import { Box, Typography, Grid, IconButton, Popper, Paper, ClickAwayListener } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { sifterOptions } from "./constants";



const ProfileSettingsButton = (props) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { preferredFilter, savePreferredFilter } = props;
    const [selectedFilter, setSelectedFilter] = useState(0);
  
    React.useEffect(() => {
  
      setSelectedFilter(preferredFilter);
  
    }, [preferredFilter]);
  
    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleFilterChange = (value) => {
      setSelectedFilter(value);
      savePreferredFilter(value);
    };
  
  
    return (
      <div>
        <IconButton onClick={handleOpen} color="primary"><Settings/></IconButton>
        <Popper open={open} anchorEl={anchorEl} placement="top-start">
          <ClickAwayListener onClickAway={handleClose}>
            <Paper>
            <Box display="flex" flexDirection="row">
                <Box p={2} sx={{alignItems:"center"}}>
                  <Typography variant="subtitle1">
                    Your Sift Mode
                  </Typography>
                  <Typography variant="subtitle2">
                    siftsy emails you in this mode. 
                  </Typography>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", gap: "1em", p:2, cursor:"pointer"}}>
                {sifterOptions.map((sifter, index) => (
                  <Grid item xs={4}>
        
                    <Typography color="primary" align="center"  variant={"subtitle2"} onClick={() => handleFilterChange(index)}
                      sx={{borderRadius:30, background : selectedFilter == index ? "#4828C5" : "#ffffff", color: selectedFilter == index ? "#fff" : "#4828C5", padding:"5px 5px", border:"2px solid #4828C5",
                        fontWeight: selectedFilter == index ? 900 : 700 }} >
                        
                      {sifter.label}
        
                    </Typography>
        
                  </Grid>
                ))}
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  };
  
  export default ProfileSettingsButton;