const ENDPOINT = process.env.REACT_APP_ENDPOINT;


const Functions = {

    fetchUser: (email) => fetchUser(email),
    listSubscriptionsByEmail: (email) => listSubscriptionsByEmail(email),
    listOwnedByEmail: (email) => listOwnedByEmail(email),
    listSiftsByEmail: (email) => listSiftsByEmail(email),
    fetchSift: (videoId, platform) => fetchSift(videoId, platform),
    updateUserSettings: (email, settings) => updateUserSettings(email, settings),
    checkAccount: (username, platform) => checkAccount(username, platform)
};

export default Functions;


async function checkAccount(username, platform){

    let req = {
        username: username,
        platform: platform
    };

    return await fetch(`${ENDPOINT}/checkaccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}


async function listSubscriptionsByEmail(email){
    
    console.log("fetching subscriptions for " + email);

    let req = {
        email: email
    };

    return await fetch(`${ENDPOINT}/listsubscriptionsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }

            else {
                return [];
            }
        }).catch((err) => console.log(err));


}


async function listOwnedByEmail(email){

console.log("fetching subscriptions for " + email);

let req = {
    email: email
};

return await fetch(`${ENDPOINT}/listownedbyemail`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
}).then((res) => res.json())
    .then((data) => {

        console.log("data", data);

        if (data && data.data) {
            return data.data;
        }
        else{
            return [];
        }
    }).catch((err) => console.log(err));


}


    
async function fetchUser(email){

    let req = {
        email: email
    };
  
    return await fetch(`${ENDPOINT}/getuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
          console.log("data", data);
          if (data && data.data) {
            let userData = data.data;
            return userData;
  }}).catch((err) => console.log(err));
  
  }


  async function listSiftsByEmail(email) {

    console.log("fetching sifts...");

    let req = {
        email: email
    };


    return await fetch(`${ENDPOINT}/listsiftsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {

                const response = {
                    sifts: data.data
                };

                return response;
            }
        }).catch((err) => console.log(err));

}

async function fetchSift(videoId, platform) {

    console.log("fetching sift...");
  
    let req = {
      postId: videoId,
      platform: platform
    };
  
    return await fetch(`${ENDPOINT}/getsift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    }).then((res) => res.json())
      .then((data) => {
  
        console.log("data", data);
  
        if (data && data.data && data.data.sift) {
  
          const response = {
            fetchedSift: JSON.parse(data.data.sift),
            fetchedData: data.data,
            fetchedCommentData: JSON.parse(data.data.commentData),
            totalComments: data.data.totalComments,
          };
  
          return response;
        }
      })
  
      .catch((err) => {
        console.log("err", err);
      });
  
  }
  
  async function updateUserSettings(email, settings){
  
    let req = {
        email: email,
        settings: settings
    };
  
    return await fetch(`${ENDPOINT}/updateuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }
  