import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, ButtonBase, Skeleton } from '@mui/material';
import { Comment, CommentOutlined, FavoriteBorder, Reply, Send, SendToMobile } from '@mui/icons-material';

const TikTokCommentCard = styled(Card)(({ theme }) => ({
  width:"100%",
  background:"transparent",
  margin: '0 auto',
  '& .MuiCardHeader-avatar': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CircularAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: '50%',
    height:50,
    width:50,
    marginRight:14,
    background:theme.palette.primary.main,
  }));
  

const TikTokComment = (props) => {
  const { comment } = props;

  const avatarSrc = comment.avatarlink ? comment.avatarlink : "";

  // React.useEffect(() => {

  //   async function fetchData(){
  //     const response = await fetchAvatar(comment.userlink.replace("/", "").toString());
  //     if(response){
  //     setAvatarSrc(response.url ? response.url : "");
  //   }
  //   setLoaded(true);
  //   }

  //   fetchData();

  // }, []);

  return (
      <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", pb:3}}>

        
              <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'top', width:"100%"}}>

                <Box sx={{display:"flex"}}>
             
                  <CircularAvatar src={avatarSrc} />
                  
                
                  
                  </Box>
                  
        
                  <Box sx={{ display: 'flex', flexDirection: "column", justifyContent:"flex-start"}}>

                  <Box sx={{display:"flex", flexDirection:"row"}}>
                          <Typography variant="h5" align="left" style={{cursor:"pointer"}} onClick={comment.userlink ? () => window.open("https://tiktok.com"+ comment.userlink, "_blank")
                          
                          : null}>
                              {comment.username}
                          </Typography>
                          <Box sx={{flexGrow:1}} />
                          </Box>
                      

                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "top"}}>
                         
                          <Box sx={{ display:"flex", flexDirection:"column", justifyContent: "flex-start"}}>
                              <Typography variant="body1" align="left" component="p">
                                  {comment.commentText}
                              </Typography>
                              
                          </Box>


                          <Box sx={{ flexGrow: 1 }} />

                  </Box>

                  </Box>
                  </Box>   
     
      </Box>
  );
};


function addLineBreaksToSentenceEnds(text) {
    // Use a regular expression to find sentence-ending punctuation and add a line break
    const stringWithLineBreaks = text.replace(/([.!?])\s/g, "$1\n\n");
  
  
    const processedText = stringWithLineBreaks.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  
  
    return processedText;
  }
  

  function CommentSummary(props) {
    // Use a regular expression to find sentence-ending punctuation and add a line break
    const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");
    const length = stringWithLineBreaks.split('\n').length;
  
    const [more, setMore] = React.useState(false);
  
    React.useEffect(() => {
      setMore(false);
    }, [props.text])
  
    return(
      <>
      {stringWithLineBreaks.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span style={{ display: index == 0 || more ? "inline" : "none"}}>{line}
        {index == 0 && !more && length > 1 ? <span onClick={() => setMore(!more)}>.. <span style={{fontWeight:700, color:"#666",  cursor:"pointer"}}>more</span></span> : " "}
        </span>
      </React.Fragment>


 
      ))}

    {more ? <span onClick={() => setMore(!more)}><span style={{fontWeight:700, color:"#666",  cursor:"pointer"}}>less</span></span> : null}

      </>
    
    )}

export default TikTokComment;


async function fetchAvatar(username){
  let req = {
      videoId: "none",
      username: username,
      imgType:"avatar"};

  return await fetch(`${process.env.REACT_APP_ENDPOINT}/fetchimg`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
  }).then((res) => res.json())
  .then((data) => {
      
        console.log("data", data);

        if (data && data.data) {

          const response = {
            url: data.data
          };

          return response;

        }

        else{
          return null;
        }


      }).catch((err) => console.log(err));
        

}


